import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Link, Box, Text, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "50px 0 50px 0",
	"quarkly-title": "Footer-3"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"margin": "0px 0px 45px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"md-margin": "0px 0px 0px 0px",
			"display": "grid",
			"md-justify-content": "center",
			"md-align-items": "center",
			"justify-content": "center",
			"sm-flex-wrap": "wrap",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"sm-grid-template-columns": "1fr"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "#5a5d64",
			"margin": "0px 6px 0px 0px",
			"href": "/index",
			"font": "normal 400 18px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"hover-color": "--darkL1",
			"transition": "background-color 0.1s ease 0s",
			"sm-text-align": "center",
			"md-margin": "0px 0 0px 0px",
			"children": "Главная"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 6px 0px 6px",
			"href": "/about",
			"font": "normal 400 18px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"color": "#5a5d64",
			"hover-color": "--darkL1",
			"transition": "background-color 0.1s ease 0s",
			"sm-text-align": "center",
			"md-margin": "0px 0 0px 6px",
			"children": "О компании"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 6px 0px 6px",
			"href": "/contact-us",
			"font": "normal 400 18px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"color": "#5a5d64",
			"hover-color": "--darkL1",
			"transition": "background-color 0.1s ease 0s",
			"sm-text-align": "center",
			"md-margin": "0px 0 0px 6px",
			"children": "Контакты"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0 0px 0 0px",
			"font": "--base",
			"color": "#5a5d64",
			"text-align": "center",
			"sm-display": "flex",
			"sm-flex-wrap": "wrap",
			"children": "emblazingaurora.com © 2024 Все права защищены."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" sm-align-items="center" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
				<Link {...override("link2")} />
			</Box>
		</Box>
		<Text {...override("text")} />
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;